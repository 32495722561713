.l-teamEve {
  padding-top: 85px;
  padding-bottom: 30px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: calc(50% + 52px);
    left: calc(12.5% + 117px);
    width: 234px;
    height: 208px;
    background-color: #eee;
    z-index: -1;
    background: url("../../../assets/svgs/dot.svg") no-repeat;

    @media screen and (max-width: 1199px) {
      top: 52px;
      left: calc(50% - 117px);
    }
  }

  .teamEve-title {
    font-weight: 800;
    font-size: 48px;
    line-height: 65px;
    letter-spacing: 0.055em;
    color: #000000;
    margin-bottom: 64px;

    @media screen and (max-width: 1199px) {
      text-align: center;
    }
  }

  .teamEve-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    @media screen and (max-width: 1199px) {
      justify-content: center;
    }

    & > div {
      margin-bottom: 85px;

      @media screen and (max-width: 1199px) {
        &:not(odd) {
          margin-left: 30px;
        }
      }

      @media screen and (max-width: 991px) {
        &:not(odd) {
          margin-left: 10px;
        }
      }

      @media screen and (max-width: 767px) {
        margin-bottom: 30px;

        &:not(odd) {
          margin-left: unset;
        }
      }

      &:nth-child(2) {
        top: 30px;

        @media screen and (max-width: 1199px) {
          top: unset;
        }
      }

      &:nth-child(4) {
        transform: translateX(50%);

        @media screen and (max-width: 1199px) {
          transform: unset;
        }
      }

      &:nth-child(5) {
        transform: translateX(-50%);

        @media screen and (max-width: 1199px) {
          transform: unset;
        }
      }
    }
  }
}
