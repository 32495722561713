.l-footer {
  background-color: #011528;

  @media screen and (max-width: 767px) {
    padding: 30px 0;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 120px;

    @media screen and (max-width: 425px) {
      flex-direction: column;
    }
  }

  .brand-footer {
    text-align: center;
    text-transform: uppercase;

    @media screen and (max-width: 425px) {
      margin-bottom: 15px;
    }

    .top,
    .bottom {
      display: block;
      color: #ffffff;
    }

    .top {
      font-weight: bold;
      font-size: 36px;
      line-height: 49px;
      letter-spacing: 0.055em;
    }

    .bottom {
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.425em;
    }
  }

  .footer-nav {
    justify-content: center;

    & > .nav-link {
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: #ffffff;
    }
  }
}
