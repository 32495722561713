.l-contacts {
  padding-top: 70px;
  background: var(--eve-darkblue);

  .lighthouse {
    position: absolute;
    bottom: -1px;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .contacts-title {
    font-weight: 800;
    font-size: 48px;
    line-height: 65px;
    letter-spacing: 0.055em;
    color: #ffffff;
    margin-bottom: 22px;

    @media screen and (max-width: 991px) {
      font-size: 36px;
    }
  }

  .contacts-text {
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.055em;
    color: #ffffff;
    margin-bottom: 24px;
  }

  .contact-form {
    margin-bottom: 175px;

    @media screen and (max-width: 991px) {
      margin-bottom: 50px;
    }

    label {
      font-size: 14px;
      line-height: 25px;
      letter-spacing: 0.055em;
      color: rgba(255, 255, 255, 0.67);
    }

    input,
    textarea {
      background: #053462 !important;
      border-radius: 5px !important;
      border: none !important;
      resize: none;
      color: #ffffff;
    }
  }
}
