.l-aboutus {
  position: relative;
  padding-top: 90px;
  margin-bottom: 55px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 458px;
    background: linear-gradient(
      180deg,
      var(--eve-darkblue) 16.45%,
      var(--eve-blue) 50.46%,
      #ffffff 88.65%
    );
  }

  .aboutus-title {
    font-weight: 800;
    font-size: 48px;
    line-height: 65px;
    letter-spacing: 0.055em;
    color: #ffffff;
    text-align: center;
    margin-bottom: 195px;

    @media screen and (max-width: 991px) {
      margin-bottom: 100px;
    }
  }

  .aboutus-subtitle {
    font-weight: 800;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: 0.055em;
    color: #000000;
    margin-bottom: 30px;

    @media screen and (max-width: 991px) {
      text-align: center;
    }
  }

  .aboutus-text {
    font-weight: normal;
    font-size: 14px;
    line-height: 180.68%;
    letter-spacing: 0.055em;
    color: var(--eve-grey);
    margin-bottom: 45px;

    @media screen and (max-width: 991px) {
      text-align: center;
    }
  }

  .aboutus-value {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 30px;

    &.circle {
      margin-left: auto;

      img {
        width: 453px;
        height: 453px;
        border-radius: 50%;

        @media screen and (max-width: 1199px) {
          width: 375px;
          height: 375px;
        }

        @media screen and (max-width: 375px) {
          width: 320px;
          height: 320px;
        }
      }

      .value {
        position: absolute;
        bottom: 0;

        @media screen and (max-width: 991px) {
          position: relative;
          margin-bottom: -60px;
        }
      }
    }

    &.bg-grey {
      .value {
        background: #f2f2f2;
      }

      .value-title {
        color: #262626;
      }

      .value-text {
        color: #262626;
      }
    }

    &.reverse {
      .value {
        border-radius: 50px 0;
        position: relative;
        left: 65px;

        @media screen and (max-width: 1199px) {
          left: 0;
        }
      }

      img {
        left: 0;
        border-radius: 0px 0px 0px 25px;
        max-width: 360px;

        @media screen and (max-width: 991px) {
          left: 50%;
          transform: translateX(-50%);
        }

        @media screen and (max-width: 375px) {
          left: unset;
          transform: unset;
          max-width: 350px;
        }
      }
    }

    &.bg-blue {
      .value {
        background-color: var(--eve-blue);
      }
    }

    .value {
      background: #262626;
      border-radius: 0px 50px;
      padding: 25px 28px 38px 34px;
      position: relative;
      z-index: 1;
      max-width: 454px;
    }

    .value-title {
      font-weight: bold;
      font-size: 18px;
      line-height: 180.68%;
      letter-spacing: 0.055em;
      color: #ffffff;
      margin-bottom: 12px;
    }

    .value-text {
      font-weight: normal;
      font-size: 14px;
      line-height: 180.68%;
      letter-spacing: 0.055em;
      color: #ffffff;
    }

    img {
      position: relative;
      filter: drop-shadow(0px 16px 37px rgba(0, 0, 0, 0.15));
      border-radius: 0px 0px 25px 0px;
      top: -24px;
      left: 65px;

      @media screen and (max-width: 991px) {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
