.l-offers {
  background: #f5f5f5;
  border-radius: 165px 0px 0px 0px;
  min-height: 200px;
  padding-top: 75px;
  padding-bottom: 65px;

  .offers {
    max-width: 545px;
    margin-bottom: 30px;

    @media screen and (max-width: 1199px) {
      max-width: 100%;
    }
  }

  .offers-title {
    font-weight: 800;
    font-size: 48px;
    line-height: 65px;
    letter-spacing: 0.055em;
    color: #000000;
    margin-bottom: 25px;

    @media screen and (max-width: 1199px) {
      text-align: center;
    }
  }

  .offers-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 191.68%;
    letter-spacing: 0.055em;
    color: var(--eve-grey);

    @media screen and (max-width: 1199px) {
      text-align: center;
    }
  }
}

.slider {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;

  @media screen and (max-width: 1199px) {
    flex-direction: row;
    justify-content: flex-start;
  }

  .slider-items {
    margin-left: auto;
    width: 515px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    top: -145px;

    @media screen and (max-width: 1199px) {
      top: 0;
      width: 100%;
      width: 240px;
      margin-left: unset;
      flex-direction: column;
      justify-content: space-between;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }

    &::before {
      content: "";
      width: 513px;
      height: 335px;
      position: absolute;
      bottom: -145px;
      background: url("../../../assets/images/offers-img.png") center center
        no-repeat;
      background-size: contain;

      @media screen and (max-width: 1199px) {
        display: none;
      }
    }

    & > .slider-item:nth-child(odd) {
      margin-right: 24px;
      margin-bottom: 30px;

      @media screen and (max-width: 1199px) {
        margin-bottom: unset;
        margin-right: unset;
      }
    }

    & > .slider-item:nth-child(even) {
      top: -60px;

      @media screen and (max-width: 1199px) {
        top: unset;
        margin-bottom: unset;
      }
    }
  }

  .slider-item {
    background: #ffffff;
    box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.03);
    border-radius: 7px;
    width: 240px;
    height: 225px;
    padding: 25px;
    color: var(--eve-grey);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

    @media screen and (max-width: 1199px) {
      flex-direction: row;
      align-items: center;
      height: 100px;

      .slider-text {
        font-weight: 800;
        font-size: 16px !important;
        line-height: 24px !important;
        letter-spacing: 0.055em;
        margin-left: 10px;
      }
    }

    @media screen and (max-width: 991px) {
      height: unset;
    }

    &.active {
      background: linear-gradient(180deg, #1776bb 0%, #24aae2 100%), #ffffff;
      color: #fff;

      .slider-text {
        color: #fff;
      }
    }

    .slider-text {
      font-weight: 800;
      font-size: 18px;
      line-height: 191.68%;
      letter-spacing: 0.055em;
    }
  }

  .content-slider {
    background: #ffffff;
    box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.03);
    padding: 38px 33px 30px 32px;
    max-width: 545px;
    border-radius: 7px;

    @media screen and (max-width: 1199px) {
      margin-left: 15px;
      max-width: 100%;
    }

    .content-slider-title {
      font-weight: 800;
      font-size: 18px;
      line-height: 191.68%;
      letter-spacing: 0.055em;
      color: #084a8b;
      margin-bottom: 17px;
    }

    .content-slider-text {
      font-size: 14px;
      line-height: 191.68%;
      letter-spacing: 0.055em;
      color: var(--eve-grey);
    }

    .content-slider-list {
      padding-left: 15px;
    }

    .content-slider-list > li {
      font-size: 14px;
      line-height: 191.68%;
      letter-spacing: 0.055em;
      color: var(--eve-grey);
    }

    .content-body {
      min-height: 520px;
    }

    .slideshow-nav {
      display: flex;
      justify-content: center;
    }

    .slider-dot {
      height: 7px;
      width: 7px;
      margin: 0 2px;
      background-color: #ededed;
      border-radius: 5px;
      display: inline-block;
      cursor: pointer;
      transition: background-color 0.6s ease-in-out, width 0.6s ease-in-out;

      &.active {
        width: 17px;
        background-color: var(--eve-blue);
      }

      &:not(.active):hover {
        background-color: var(--eve-clearblue);
      }
    }
  }
}
