.l-navbar {
  height: 124px;
  transition: height 0.6s ease-out;

  @media screen and (max-width: 991px) {
    .navbar-collapse {
      background-color: #fff;
      padding: 20px;
      border-radius: 0 0 15px 15px;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
      position: relative;
      top: 7px;
      z-index: 1;
    }
  }

  &.didScroll {
    height: 60px;

    @media screen and (max-width: 991px) {
      height: 75px;
    }

    .navbar-brand img {
      max-height: 50px;
    }
  }

  .navbar-nav > .nav-link {
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: var(--eve-nav-link);
    transition: color 0.3s ease-in-out;
    position: relative;
    z-index: 1031;
    margin-left: 15px;

    &.custom-nav-link {
      background-color: var(--eve-darkblue);
      border-radius: 15px;
      color: #fff !important;
      padding-left: 15px;
      padding-right: 15px;

      &::before {
        display: none;
      }
    }

    @media screen and (max-width: 991px) {
      text-align: right;
      margin-bottom: 15px;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0.5rem;
      bottom: 0;
      width: 0;
      height: 3px;
      background-color: var(--eve-blue);
      transition: width 0.3s ease-in-out;

      @media screen and (max-width: 991px) {
        right: 0;
        left: unset;
      }
    }

    &.active {
      color: var(--eve-blue);

      &::before {
        width: 72px;
        max-width: 100%;
        height: 3px;
        background-color: var(--eve-blue);
      }
    }

    &:hover {
      color: var(--eve-blue);
    }
  }
}
