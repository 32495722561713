.l-missions {
  position: relative;
  margin-bottom: 95px;

  .mission-title {
    font-weight: 800;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: 0.055em;
    color: #000000;
    margin-bottom: 78px;

    @media screen and (max-width: 991px) {
      text-align: center;
    }
  }

  .missions {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    @media screen and (max-width: 991px) {
      flex-direction: column;
    }

    &::before {
      content: "";
      position: absolute;
      top: -60px;
      left: -60px;
      width: 234px;
      height: 208px;
      background-color: #eee;
      z-index: -1;
      background: url("../../../assets/svgs/dot.svg") no-repeat;

      @media screen and (max-width: 991px) {
        top: -120px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    & > .mission {
      width: 50%;

      @media screen and (max-width: 991px) {
        width: 100%;
      }

      &:first-child {
        border-right: none;
        background-color: var(--eve-clearblue);

        .mission-number,
        .mission-title,
        .mission-text {
          color: #fff;
        }
      }
    }
  }

  .mission {
    min-height: 315px;
    padding: 20px 50px;
    border: 1px solid #ededed;

    .mission-number,
    .mission-title,
    .mission-text {
      font-weight: 800;
      font-size: 72px;
      letter-spacing: 0.055em;
      color: var(--eve-grey);
      margin-bottom: 0;

      @media screen and (max-width: 991px) {
        text-align: center;
      }
    }

    .mission-title,
    .mission-text {
      font-size: 36px;
    }

    .mission-number {
      margin-bottom: 23px;
    }

    .mission-title {
      margin-bottom: 34px;

      @media screen and (max-width: 425px) {
        font-size: 24px;
      }
    }

    .mission-text {
      font-weight: normal;
      font-size: 14px;
    }
  }
}
