.l-eveconsulting {
  min-height: 535px;
  background: #fff url("../../../assets/svgs/montain-top.svg") no-repeat bottom
    right;

  @media screen and (max-width: 425px) {
    min-height: 430px;
    background-size: cover;
  }

  @media screen and (max-width: 991px) {
    .eveconsulting {
      background-color: rgba(255, 255, 255, 0.6);
      padding: 25px;
      border-radius: 15px;
      display: flex;
      flex-direction: column;

      .home-text {
        max-width: 100%;
      }

      a {
        margin: 0 auto;
      }
    }
  }

  .home-title {
    font-weight: 800;
    font-size: 48px;
    line-height: 65px;
    letter-spacing: 0.055em;
    color: #000000;
    margin-bottom: 18px;
    max-width: 630px;

    @media screen and (max-width: 485px) {
      font-size: 38px;
      line-height: 48px;
    }

    @media screen and (max-width: 425px) {
      font-size: 26px;
      line-height: 36px;
    }
  }

  .home-text {
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.055em;
    color: var(--eve-grey);
    max-width: 690px;
    margin-bottom: 35px;

    @media screen and (max-width: 1250px) {
      max-width: 550px;
    }

    @media screen and (max-width: 1199px) {
      max-width: 375px;
    }
  }
}
