.l-vision {
  margin-bottom: 55px;

  .vision {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .vision-title {
    font-weight: 800;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: 0.055em;
    color: #000000;
    margin-bottom: 25px;

    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }

  .vision-text {
    font-size: 14px;
    line-height: 27px;
    letter-spacing: 0.055em;
    color: var(--eve-grey);

    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }

  .vision-img {
    width: 435px;
    height: 435px;
    border-radius: 50%;
    border: 16px dashed #ededed;
    position: relative;

    @media screen and (max-width: 991px) {
      width: 350px;
      height: 350px;
      border-width: 16px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 30px;
      left: 50%;
      transform: translateX(-50%);
    }

    &::before {
      content: "";
      position: absolute;
      width: 85%;
      height: 85%;
      background: url("../../../assets/images/vision-img.png") center center
        no-repeat;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
  }
}
